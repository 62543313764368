import { NgModule, NgZone } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule, Http, ConnectionBackend, BaseRequestOptions, XHRBackend } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule, RouterState, Router } from "@angular/router";
import {
    IfModalComponent,
    IfLoadPopupComponent,
} from './components';
@NgModule({
	imports: [
		CommonModule,
		HttpModule,
		RouterModule//,
		//FlexLayoutModule
	],
	providers: [		
	],
	declarations: [
        IfModalComponent,
        IfLoadPopupComponent	
	],
	exports: [
		//Components
        IfModalComponent,
        IfLoadPopupComponent
	]
})
export class IfAssetsModule { }
