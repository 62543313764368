export class SalesForceUserAttributes {
    constructor() { }
    
        public FirstName: string;
        public LastName: string;
        public ApplicationName: "GEIDPLeaseWave";
        public Organisation: "GE";
        public Department: "GEHC";
        public Email: string;
        public noUMRFlag: boolean; 
        public Phone: string; 
        public Country: string; 
        public noPassword: boolean;
        public Password: string; 
        public ConfirmPassword: string; 
        public LanguageLocal: string;
        public MailingStreet: string; 
        public MailingCity: string; 
        public MailingState: string; 
        public MailingPostalCode: string; 
        public MailingCountry: string; 
}