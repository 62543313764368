import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CookieService } from 'angular2-cookie/core';
//import { BrowserAnimationsModule } from '@angular/@angular/platform-browser/animations/animations';
//import { DropdownModule } from 'ng2-bootstrap/dropdown';
import { IfAssetsModule } from './if-assets';
//import { Ng2GoogleRecaptchaModule } from './ng2-google-recaptcha';


import { AppComponent } from './app.component';
//import { ForgotPasswordComponent } from './forgot/forgot-password/forgot-password.component';
//import { ForgotPasswordChangeComponent } from './forgot/forgot-password-change/forgot-password-change.component';
//import { ForgotPasswordChangeSuccessComponent } from './forgot/forgot-password-change-success/forgot-password-change-success.component';
//import { ForgotPasswordDirectionsComponent } from './forgot/forgot-password-directions/forgot-password-directions.component';
//import { ForgotPasswordEmailSentComponent } from './forgot/forgot-password-email-sent/forgot-password-email-sent.component';
//import { ForgotUserIdComponent } from './forgot/forgot-userid/forgot-userid.component';
//import { ForgotUserIdEmailSentComponent } from './forgot/forgot-userid-email-sent/forgot-userid-email-sent.component';
//import { ForgotUserIdEmailSentService } from './forgot/forgot-userid-email-sent/forgot-userid-email-sent.service';
import { HoldingComponent } from './holding/holding.component';
import { HomeComponent } from './home/home.component';
//import { LoginChallengeComponent } from './login/login-challenge/login-challenge.component';
//import { LoginChallengeService } from './login/login-challenge/login-challenge.service';
//import { LoginImageComponent } from './login/login-image/login-image.component';
//import { LoginImageService } from './login/login-image/login-image.service';
//import { LoginPasswordComponent } from './login/login-password/login-password.component';
//import { LoginLockedOutComponent } from './login/login-locked-out/login-locked-out.component';
//import { LoginLoggedInComponent } from './login/login-logged-in/login-logged-in.component';
//import { LogoutComponent } from './logout/logout.component';
import { PreLwComponent } from './register/pre-lw/pre-lw.component';
import { ValidatedLwComponent, FocusDirective } from './register/validated-lw/validated-lw.component';
import { ValidatedLwService } from './register/validated-lw/validated-lw.service';
//import { AccountSettingsComponent } from './change/account-settings/account-settings.component';
//import { ChangeUsernameComponent } from './change/change-username/change-username.component';
//import { ChangeQAComponent } from './change/change-qa/change-qa.component';
//import { ChangePasswordComponent } from './change/change-password/change-password.component';
//import { ChangeImagePhraseComponent } from './change/change-image-phrase/change-image-phrase.component';

@NgModule({
    declarations: [
        AppComponent,
        HoldingComponent,
        HomeComponent,
        PreLwComponent,
        ValidatedLwComponent,
        FocusDirective
    ],
    imports: [
        //DropdownModule.forRoot(),
        BrowserModule,
        RouterModule.forRoot([
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'holding', component: HoldingComponent },
            { path: 'home', component: HomeComponent },
            { path: 'register/preLw', component: PreLwComponent },
            { path: 'register/validatedLw', component: ValidatedLwComponent },

            { path: '**', redirectTo: 'home' }
        ]),
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        IfAssetsModule,
        //Ng2GoogleRecaptchaModule
    ],
    /* providers here are anything to persist between view changes */
    providers: [ValidatedLwService, CookieService],
    bootstrap: [AppComponent]
})
export class AppModule { }
