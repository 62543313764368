import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions, RequestMethod } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import {
    AddUserParams,
    LeasewaveAttributes,
    UserIsAvailableParameters
} from './register-api/registrationParameters';
import { Image } from '../eas/image';
import { Question } from '../eas/question';

@Injectable()
export class RegisterApiService {

    constructor(private http: Http) { }

    validateLw(leasewaveAttributes: LeasewaveAttributes): Observable<boolean> {
        return this.http.post('api/registration/validatelw', leasewaveAttributes)
                        .map(res => res.json());
    }
    
    postNewUser (addUserParams: AddUserParams): Observable<void> {     
        return this.http.post('api/registration/register', addUserParams)
                        .map(res => res.json());                                        
    }
    
    postSFDCUser (addUserParams: AddUserParams): Observable<void> {  

     let bodySFDC = {
        "Email":addUserParams.attributes.mail,
        "FirstName":addUserParams.attributes.givenName,
        "LastName":addUserParams.attributes.surname,
        "Password": addUserParams.attributes.password,
        "ConfirmPassword":addUserParams.attributes.password,
        "noUMRFlag" : true, 
        "Phone":"3333322222", 
        "Country":"US", 
        "ApplicationName" :"GEIDPLeaseWave", 
        "noPassword" : false, 
        "Department" :"GEHC", 
        "LanguageLocal" :"English", 
        "MailingStreet" : "Longwood Avenue Boston", 
        "MailingCity" : "Greater Boston Area", 
        "MailingState" : "Boston", 
        "MailingPostalCode" : "02115", 
        "MailingCountry" : "US" 
        }   
        return this.http.post('api/registration/registerSFDC', bodySFDC)
                        .map(res => res.json());                                        
    }
     
    checkUserIsAvailable(username: string): Observable<any> {
        let rParams: UserIsAvailableParameters = new UserIsAvailableParameters(username);
        return this.http.post('api/registration/userisavailable', rParams)
                   .map(res => res.json());
    }

    getImages(): Observable<Image[]> {
        return this.http.get('api/registration/images')
                   .map(res => res.json());
    }

    getImagesByCategory(browseImageCategory: string): Observable<Image[]> {

        if (browseImageCategory === "Random Images")
            browseImageCategory = "All Images";

        return this.http.get('api/registration/imagesbycategory?browseImageCategory=' + browseImageCategory)
            .map(res => res.json());
    }

    getImageCategories(): Observable<string[]> {
        return this.http.get('api/registration/imagecategories')
            .map(res => res.json());
    }

    getQuestions(): Observable<Question[][]> {
        return this.http.get('api/registration/questions')
                   .map(res => res.json());
    }

}
