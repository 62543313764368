import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute, Params} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

import { RegisterApiService } from '../../common/api/register-api.service';
import { LeasewaveAttributes } from '../../common/api/register-api/registrationParameters';
import { ValidatedLwService } from '../validated-lw/validated-lw.service';
import { isBlank } from '../../common/stringUtils';
import { LeasewaveValidationResponse } from '../../common/api/register-api/leasewaveValidationResponse';


@Component({
    selector: 'register-pre-lw',
    templateUrl: './pre-lw.component.html',
    styleUrls: ['./pre-lw.component.css'],
    providers: [RegisterApiService]
})
export class PreLwComponent implements OnInit {
    preRegistrationForm: FormGroup;
    eitherOrFormGroup: FormGroup;
    errorMessage: string = '';
    loadingMsg: string = 'Checking Details...';
    showLoading: Boolean = false;
    continueButton: boolean = false;
    showUsernameRules: boolean = false;
    usernameIsChecked: boolean = false;
    usernameIsAvailable: boolean = false;
    secondaryValidation = true;

    constructor(
        private registerApiService: RegisterApiService,
        private validatedLwService: ValidatedLwService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder)
    { this.prepareForm(); }

    ngOnInit() { }


    prepareForm() {
        this.preRegistrationForm = this.fb.group({
            accountScheduleNumber: ['', [Validators.pattern(/^[0-9\-]*$/)]],
            billingId: ['', [Validators.pattern(/^[A-Za-z0-9\-]*$/)]],
            zipCode: ['', [Validators.pattern(/^(\d{5}([\-]\d{4})?)$/), Validators.required]],  
            //zipCode: ['', [Validators.pattern(/^(?=.*[$@$!%*?&#-_~()`"{}|])[A-Za-z0-9\d$@$!%*?&#-_~()`"{}| ]{8,256}/),Validators.required]],         
            emailAddress: ['', [Validators.pattern(/^[A-Za-z0-9\.\_\-\+]+\@((?!ge|med.ge).)[A-Za-z0-9\.\+\_\-\@]+\.[A-Za-z0-9]+$/), Validators.required]]
        }, { validator: this.isOneOrFieldFilled });
    }

    captureAttributesFromForm(): LeasewaveAttributes {
        let leasewaveAttributes = new LeasewaveAttributes();
        leasewaveAttributes.accountScheduleNumber = this.preRegistrationForm.controls['accountScheduleNumber'].value;
        leasewaveAttributes.billingId = this.preRegistrationForm.controls['billingId'].value;
        leasewaveAttributes.zipCode = this.preRegistrationForm.controls['zipCode'].value;
        leasewaveAttributes.emailAddress = this.preRegistrationForm.controls['emailAddress'].value;
        //localStorage.setItem(leasewaveAttributes.emailAddress);     
        return leasewaveAttributes;
    }

    // Here, "eitherOrFormGroup" includes a validation that exactly
    // one field between account number and billing id is populated.
    isOneOrFieldFilled(group: FormGroup) {
        let accountScheduleNumberCtrl = group.controls['accountScheduleNumber'];
        let billingIdCtrl = group.controls['billingId'];
        const isAsnBlank = isBlank(accountScheduleNumberCtrl.value);
        const isBidBlank = isBlank(billingIdCtrl.value);
        const isExactlyOneBlank = ((isAsnBlank && !isBidBlank) || (!isAsnBlank && isBidBlank));
        return isExactlyOneBlank ? null : { isOneOrFieldFilled: { valid: false } };
    }

    isControlError(formControlName: string): boolean {
        const control = this.preRegistrationForm.controls[formControlName];
        return !control.valid && (control.touched || this.continueButton);
    }

    isOneOrFieldFilledError(formControlName: string) {
        const control = this.preRegistrationForm.controls[formControlName];
        if (!control.touched && !this.continueButton) { return false; }
        if (!control.valid) { return true; }
        if (null != this.preRegistrationForm.errors) {
            if (this.preRegistrationForm.errors.hasOwnProperty('isOneOrFieldFilled')) {
                return true;
            }
        }
        return false;
    }

    submitLeasewaveAttributes() {
         
        this.continueButton = true;

        if (this.preRegistrationForm.valid && this.usernameIsAvailable == true) {
            this.captureAttributesFromForm();
            this.errorMessage = '';
            this.showLoading = true;

            let onError = (resp: LeasewaveValidationResponse) => {
                this.showLoading = false;
                if (resp.failureReason !== undefined)
                    this.errorMessage = this.errorMessage = 'Unable to complete your request at this time. Please contact Customer Service at 800-225-7480.';
                else if (resp["_body"] !== undefined)
                    this.errorMessage = this.errorMessage = 'Unable to complete your request at this time. Please contact Customer Service at 800-225-7480.';
                else
                    this.errorMessage = this.errorMessage = 'Unable to complete your request at this time. Please contact Customer Service at 800-225-7480.';
            };
            let onSuccess = (resp: boolean) => {
                this.showLoading = false;

                if (resp !== true) {
                    this.errorMessage = this.errorMessage = 'The Combination of Billing Code or Account Schedule Number and Zipcode is invalid.';
                }
                else {
                    this.validatedLwService.leasewaveAttributes = this.captureAttributesFromForm();
                    this.router.navigate(['register/validatedLw']);
                }
            };
            this.registerApiService.validateLw(this.captureAttributesFromForm())
                .subscribe(onSuccess, onError);
        }
    }

    usernameCheckTimeCount: number = 0;
    usernameChanged(): void {
        this.usernameIsChecked = false;
        this.usernameIsAvailable = false;
        this.usernameCheckTimeCount += 1;
        let usernameCheckTimeCount = this.usernameCheckTimeCount;
        setTimeout(() => {
            if (usernameCheckTimeCount === this.usernameCheckTimeCount) {
                this.usernameCheckTimeCount = 0;
                this.checkUsername();
            }
        }, 1000);
    }

    checkUsername(): void {
        
        this.showUsernameRules = false;
        let username: string = this.preRegistrationForm.controls['emailAddress'].value;
        if ('undefined' !== username && '' !== username) {
            this.registerApiService.checkUserIsAvailable(username)
                .subscribe(userIsAvailable => {
                    this.usernameIsChecked = true;
                    this.showLoading = true;
                    this.usernameIsAvailable = userIsAvailable;
                    this.showLoading = false;
                });
        }
    }
     showUserRules(): void {
        this.showUsernameRules = true;
    }

    hideUserRules(): void {
        this.showUsernameRules = false;
    }
}
