export class UserAttributes {
    constructor() { }
    
    public givenName: string;
    public linkedBu: string;
    public mail: string;
    public orgId: string;
    public password: string;
    public selfReg: string;
    public surname: string;
    public userName: string;
    public gessouid: string;
    public currentPassword: string;
    public ssoStatus: string;
    public sessionId: string;
    public transactionId: string;
    public userLanguage: string;
    public userCountry: string;
    public areaCode: string;
    public countryCode: string;
    public phoneNumber: string;
    public ssn: string;
    /* public System.Nullable<SSNType> ssnType: ; */
    public ssnTypeFieldSpecified: boolean;
    public street: string;
    public town: string;
    public state: string;
    public postalCode: string;
}
