import { Component, Directive, OnInit, ElementRef, Input, HostListener } from '@angular/core';
import { Router, RouterModule, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ValidatedLwService } from './validated-lw.service';
import { RegisterApiService } from '../../common/api/register-api.service';
import { AddUserParams } from '../../common/api/register-api/registrationParameters';
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
//import { UserAttributes } from '../../common/eas/userAttributes';
//import { Image } from '../../common/eas/image';
//import { Question } from '../../common/eas/question';
import { LeasewaveValidationResponse } from '../../common/api/register-api/leasewaveValidationResponse';


@Directive({
    selector: '#userIdTextbox'
})
export class FocusDirective {
    constructor() { }

    @HostListener('focusout', ['$event.target'])
    onFocusOut(target) { }
}

@Component({
    selector: 'app-register',
    templateUrl: './validated-lw.component.html',
    styleUrls: ['./validated-lw.component.css'],
    providers: [RegisterApiService]
})
export class ValidatedLwComponent implements OnInit {

    addUserParams: AddUserParams = new AddUserParams();
    registrationForm: FormGroup;
    passwords: FormGroup;
    usernameIsChecked: boolean = false;
    usernameIsAvailable: boolean = false;
    //images: Image[] = [];
    //imageCategories: string[] = [];
    //chosenImageCategory: string = 'Random Images';
    //questions1: Question[] = [];
    //questions2: Question[] = [];
    //questions3: Question[] = [];

    //question1Validation: string = '';
    //question2Validation: string = '';
    //question3Validation: string = '';

    //imageValidation: string = '';

    //secondaryValidation = true;

    //questionsText: string[] = ['Select...', 'Select...', 'Select...'];
    //questionsClicked: boolean[] = [false, false, false, false];
    confirmPassword: string;
    showTermsConditions: boolean = false;
    modalPopupTitle: string = 'Terms and Conditions';
    showTermsOfUse: boolean = true;
    errorMessage: string = '';
    loadingMsg: string = 'Registering User...';
    showLoading: boolean = false;
    showPasswordRules: boolean = false;
    //showUsernameRules: boolean = false;
    submitPressed: boolean = false;

    constructor(private registerApiService: RegisterApiService,
        private validatedLwService: ValidatedLwService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder) {
        this.addUserParams.attributes.mail = this.validatedLwService.leasewaveAttributes.emailAddress;
        console.log('i am here:', this.validatedLwService.leasewaveAttributes.emailAddress)
        this.addUserParams.leasewaveAttributes = this.validatedLwService.leasewaveAttributes;
        this.passwords = this.fb.group({
            //password: ['', [Validators.required,Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#-_~()`"{}|])[A-Za-z\d$@$!%*?&#-_~()`"{}|]{8,256}/)]],
            password: ['', [Validators.required,Validators.pattern(/^(?=.*[$@$!%*?&#-_~()`"{}|])[A-Za-z0-9\d$@$!%*?&#-_~()`"{}| ]{8,256}/)]],
            confirmPassword: ['', [Validators.required,Validators.pattern(/^(?=.*[$@$!%*?&#-_~()`"{}|])[A-Za-z0-9\d$@$!%*?&#-_~()`"{}| ]{8,256}/)]],
        }, { validator: this.passwordsAreEqual });
        this.registrationForm = this.fb.group({
            givenName: ['', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9 ]*$/)]],
            surname: ['', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9 ]*$/)]],
            username: ['', [Validators.pattern(/^[A-Za-z0-9\.\_\-]+\@[A-Za-z0-9\.\_\-\@]+\.[A-Za-z0-9]+$/)]],
            passwords: this.passwords,
            //answer1: ['', [Validators.required]],
            //answer2: ['', [Validators.required]],
            //answer3: ['', [Validators.required]],
            //phrase: ['', [Validators.required, Validators.pattern(/^[A-Za-z]+$/)]],
            //termsCheckbox: [false, Validators.pattern('true')]
        });
    }

    ngOnInit() {
}
    isControlError(formGroup: FormGroup, formControlName: string): boolean {
        const control = formGroup.controls[formControlName];
        return !control.valid && (control.touched || this.submitPressed);
    }

    isControlEmpty(formGroup: FormGroup, formControlName: string): boolean {
        const control = formGroup.controls[formControlName];
        const controlValue = formGroup.controls[formControlName].value;
        return controlValue === '' && (control.touched || this.submitPressed);
    }

    passwordsAreEqual(passwordGroup: FormGroup) {
        const passwordValue = passwordGroup.controls['password'].value;
        const confirmValue = passwordGroup.controls['confirmPassword'].value;
        return passwordValue === confirmValue ? null : { passwordsAreEqual: { value: false } };
    }

    captureFieldsFromForm() {
        this.addUserParams.attributes.givenName = this.registrationForm.controls['givenName'].value;
        this.addUserParams.attributes.surname = this.registrationForm.controls['surname'].value;
        this.addUserParams.attributes.userName = this.addUserParams.attributes.mail;
        //this.addUserParams.attributes.userName =  this.registrationForm.controls['username'].value;
        console.log('this.addUserParams.attributes.userName:',this.addUserParams.attributes.userName);
        //this.addUserParams.attributes.userName = this.validatedLwService.leasewaveAttributes.emailAddress;
        this.addUserParams.attributes.password = this.passwords.controls['password'].value;

        //this.addUserParams.answers[0] = this.registrationForm.controls['answer1'].value;
        //this.addUserParams.answers[1] = this.registrationForm.controls['answer2'].value;
        //this.addUserParams.answers[2] = this.registrationForm.controls['answer3'].value;

        //this.addUserParams.phrase = this.registrationForm.controls['phrase'].value;

        console.log('Here :',this.addUserParams.attributes)

    }
    
    submitNewUser(): void {
        this.submitPressed = true;

        if (this.registrationForm.valid) {
            this.captureFieldsFromForm();

                this.errorMessage = '';
                this.showLoading = true;

                let onError = (resp: LeasewaveValidationResponse) => {

                    this.showLoading = false;
                    if (resp.failureReason !== undefined)
                        this.errorMessage = resp.failureReason;
                    else
                        this.errorMessage = 'Unable to complete your request at this time. Please contact Customer Service at 800-225-7480.';
                };
                
                let onSuccess = () => {
                    this.showLoading = false;
                    this.router.navigate(['home']);
                };
                 
                this.registerApiService.postSFDCUser(this.addUserParams)
                    .subscribe(); 

                this.registerApiService.postNewUser(this.addUserParams)
                    .subscribe(onSuccess, onError);
                    
                alert ("Your account has been Registered Successfully")
            }

    }

    showRules(): void {
        this.showPasswordRules = true;
    }

    hideRules(): void {
        this.showPasswordRules = false;
    }

}