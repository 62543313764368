import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
//import { HomeService } from './home.service';
//import { SessionApiService } from '../common/api/session-api.service';
//import { SessionUserInfoResponse } from '../common/api/session-api/sessionResponse';
//import { LoginChallengeService } from '../login/login-challenge/login-challenge.service';
//import { LoginApiService } from '../common/api/login-api.service';
//import { StatusResponse } from '../common/api/login-api/loginResponse';
import { UserAttributes } from '../common/eas/userAttributes';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    //providers: [LoginApiService, SessionApiService]
})
export class HomeComponent implements OnInit {

    homeForm: FormGroup;
    errorMessage: string = '';
    showLoading: Boolean = false;
    loadingMsg: string = 'Checking Username...';
    continueButton: boolean = false;
    attributes: UserAttributes;
    //sessionUserInfoResponse: SessionUserInfoResponse;
    isCollapsed = false;

    toggleCollapse(event: Event): void {
      event.preventDefault(); // Prevent default anchor behavior
      this.isCollapsed = !this.isCollapsed;
    }
    constructor(
        //private sessionApiService: SessionApiService,
        //private loginChallengeService: LoginChallengeService,
        //private loginApiService: LoginApiService,
        //private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder
    ) { this.prepareForm(); }

     ngOnInit() { }


    prepareForm() {
        this.homeForm = this.fb.group({
            userId: ['', [Validators.pattern(/^[A-Za-z0-9\.\_\-]+$/), Validators.required]]
        });
    }

    goToUrl(): void {
        //window.location.href= 'https://sit-mygecustomerportal.cloud.health.ge.com/CustomerPortal';
        //window.location.href= 'https://test-mygecustomerportal.cloud.gehealthcare.com/CustomerPortal';
          window.location.href= 'https://https://uat-hfscustomerportal.gehealthcare.com/CustomerPortal';
        }

}
