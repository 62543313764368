declare var add_deviceprint: any; // included in rsa.js for EAS device print

import { UserAttributes } from '../../eas/userAttributes';
import { Image } from '../../eas/image';
import {SalesForceUserAttributes} from '../../eas/salesForceUserAttributes';

export class LeasewaveAttributes {
    accountScheduleNumber: string = '';
    billingId: string = '';
    zipCode: string = '';
    emailAddress: string = '';

    constructor() {
    }
}

export class UserIsAvailableParameters {
    username: string;
    devicePrint: string;

    constructor(username: string) {
        this.username = username;
        this.devicePrint = add_deviceprint();
    }
}

export class AddUserParams {
    leasewaveAttributes: LeasewaveAttributes;
    attributes: UserAttributes;
    sfdcAttributes: SalesForceUserAttributes; 
    image: Image;
    phrase: string;
    questionNames: string[];
    answers: string[];
    devicePrint: string;
    bindDevice: string;
    
    constructor() {
        this.leasewaveAttributes = new LeasewaveAttributes();
        this.attributes = new UserAttributes();
        this.sfdcAttributes= new SalesForceUserAttributes(); 
        this.image = new Image();
        this.questionNames = ['','',''];
        this.answers = ['','',''];
        this.devicePrint = add_deviceprint();
        this.bindDevice = 'false';
    }
}
