import { Injectable } from '@angular/core';

import { LeasewaveAttributes } from '../../common/api/register-api/registrationParameters';

@Injectable()
export class ValidatedLwService {
    constructor() { }

    leasewaveAttributes: LeasewaveAttributes = new LeasewaveAttributes();
}
