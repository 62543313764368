import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs/Observable';

@Component({
    selector: 'holding',
    templateUrl: './holding.component.html',
    styleUrls: ['./holding.component.css'],
    providers: []
})
export class HoldingComponent implements OnInit {

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit() {
    }

}
