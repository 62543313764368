import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'if-load-popup',
  templateUrl: './if-load-popup.component.html',
  styleUrls: ['./if-load-popup.component.scss']
})
export class IfLoadPopupComponent implements OnInit {

  @Input() show: boolean;
  @Input() msg: string;
  
  constructor() { }

  ngOnInit() {
  }

}
