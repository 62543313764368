import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {  trigger, transition, style, animate } from '@angular/animations';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@Component({
  selector: 'if-modal',
  templateUrl: './if-modal.component.html',
  styleUrls: ['./if-modal.component.scss'],
  animations:[
	  trigger('modal', [
      transition('* => void', animate('300ms ease-in',style({top:0, transform: 'translate(-50%,-100%)'}))),
      transition('void => *', [style({top:0, transform: 'translate(-50%,-100%)'}),animate('300ms ease-out',style({top:'*', transform: '*'}))])
    ]),
	  trigger('modalShadow', [
      transition('* => void', animate('300ms',style({opacity:0}))),
      transition('void => *', [style({opacity:0}),animate('300ms',style({opacity:'*'}))])
    ]),

  ]
})
export class IfModalComponent implements OnInit {

  @Input() modalTitle: string;
  @Input() theme: string;
  @Input() show: boolean;
  @Input() cancelMsg: string;
  @Output() onCancel = new EventEmitter();
  //@Input() actionButtons: IfButtonConfig[];
  @Input() actionDisabled: boolean;


  constructor() { }

  ngOnInit() {
  }

  emitCancel() {
    this.onCancel.emit();
  }
}
